<!--创建设计-->
<template>
  <div class="panel-mainContent">
    <div class="boxMargins">
      <!--          头部-->
      <div class="box_title">
        <h2>创建设计</h2>
        <div style="width: 40%;min-height: 40px;">
          <div class="custom_box">
            <div class="custom" v-show="customIf">
              <el-input @input="handleInput(widthValue,1)" size="large" v-model="widthValue" style="width: 100px" placeholder="宽"/>
              <div>×</div>
              <el-input @input="handleInput(heightValue,2)" size="large" v-model="heightValue" style="width: 100px" placeholder="高"/>
              <NuxtLink :to="'/designs/create/'+btoa(widthValue +'_'+heightValue) ">
                <el-button class="btn" :disabled="widthValue && heightValue?false:true" type="primary">创建设计</el-button>
              </NuxtLink>
            </div>
            <el-button class="btn" @click="getCustom" type="primary">自定义尺寸</el-button>
          </div>
        </div>
      </div>
      <!--          选择菜单-->
      <div class="box_menu">
        <div class="box_menu_data" :class="menuIndex===-1?'box_menu_select':''" @click="getMenu(-1)">
          全部
        </div>
        <div class="box_menu_data" :class="menuIndex===index?'box_menu_select':''" @click="getMenu(index,item)"
             v-for="(item,index) in menuList">
          {{ item.title }}
        </div>
      </div>
      <!--          线-->
      <div class="box_line"></div>
    </div>
    <!--        收藏swiper-->
    <div class="box_img" v-show="menuIndex===-1 && collectionImgList.length!==0">
      <h3>收藏</h3>
      <swiper style="z-index: 0" :pagination="{clickable: true,}" :speed="600" :breakpoints=breakpointsData
              :navigation="{  nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev'}"
              :modules="modules" @mouseenter="collectionShow=true" @mouseleave="collectionShow=false">
        <swiper-slide @mouseenter="collectionShowState=item2.id" @mouseleave="collectionShowState=-1"
                      v-for="(item2,index2) in collectionImgList"
                      :style="{ minHeight: swiperHeight,minWidth: swiperminWidth}">
          <div class="box_img_data">
            <div class="image">
              <div v-show="collectionShowState===item2.id" class="overlayCreateDesign"></div>
              <img :class="item2.id==collectionShowState?'enlarge':''" :src="item2.icon" alt="">
              <client-only>
                <el-tooltip
                    :show-after="200"
                    class="box-item"
                    effect="dark"
                    :offset="3"
                    :show-arrow="false"
                    content="收藏"
                    placement="bottom-end">
                  <div v-show="item2.id==collectionShowState"
                       @click="getCollection(item2.id,'collectionCancel',item2)" class="collection">
                    <i style="color: red" class="iconfont icon-hear-full"></i>
                  </div>
                </el-tooltip>
              </client-only>
              <client-only>
                <el-tooltip
                    :show-after="200"
                    class="box-item"
                    effect="dark"
                    :offset="-16"
                    :show-arrow="false"
                    content="创建画布"
                    placement="bottom-start">
                  <NuxtLink :to="'/designs/create/'+btoa(item2.width +'_'+item2.height) ">
                    <div v-show="item2.id==collectionShowState" class="establish">
                      <i class="iconfont icon-jiahao"></i>
                    </div>
                  </NuxtLink>
                </el-tooltip>
              </client-only>
              <div v-show="item2.id==collectionShowState" class="writtenWords"></div>
            </div>
            <div class="text">{{ item2.title }}</div>
            <span>{{ item2.width }}×{{ item2.height }}</span>
          </div>
        </swiper-slide>
        <div class="swiper-button-next" v-show="collectionShow"><i class="iconfont icon-ico_arrowright"></i></div>
        <div class="swiper-button-prev" v-show="collectionShow"><i class="iconfont icon-xiangzuojiantou"></i></div>
      </swiper>
    </div>
    <!--          图片内容-->
    <div class="box_img" v-for="(item,index) in imgList">
      <h3>{{ item.title }}</h3>
      <swiper style="z-index: 0" :pagination="{clickable: true,}" :speed="600" :breakpoints=breakpointsData
              :navigation="{  nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev'}"
              :modules="modules" @mouseenter="handleMouseEnter(index)" @mouseleave="handleMouseLeave()">
        <swiper-slide @mouseenter="showState=item2.id" @mouseleave="showState=-1"
                      v-for="(item2,index2) in item.child"
                      :style="{ minHeight: swiperHeight,minWidth: swiperminWidth}">
          <div class="box_img_data">
            <div class="image">
              <div v-show="showState===item2.id" class="overlayCreateDesign"></div>
              <img :class="item2.id==showState?'enlarge':''" :src="item2.icon" alt="">
              <client-only>
                <el-tooltip
                    :show-after="200"
                    class="box-item"
                    effect="dark"
                    :offset="3"
                    :show-arrow="false"
                    content="收藏"
                    placement="bottom-end">
                  <div v-show="item2.id==showState" @click="getCollection(item2.id,index,index2)"
                       class="collection">
                    <i :style="{color:item2.likestate?'red':''}" class="iconfont icon-hear-full"></i>
                  </div>
                </el-tooltip>
              </client-only>
              <client-only>
                <el-tooltip
                    :show-after="200"
                    class="box-item"
                    effect="dark"
                    :offset="-16"
                    :show-arrow="false"
                    content="创建画布"
                    placement="bottom-start">
                  <NuxtLink :to="'/designs/create/'+btoa(item2.width +'_'+item2.height)">
                    <div v-show="item2.id==showState" class="establish">
                      <i class="iconfont icon-jiahao"></i>
                    </div>
                  </NuxtLink>
                </el-tooltip>
              </client-only>
              <div v-show="item2.id==showState" class="writtenWords"></div>
            </div>
            <div class="text">{{ item2.title }}</div>
            <span>{{ item2.width }}×{{ item2.height }}</span>
          </div>
        </swiper-slide>
        <div class="swiper-button-next" v-show="operationId===index"><i class="iconfont icon-ico_arrowright"></i>
        </div>
        <div class="swiper-button-prev" v-show="operationId===index"><i class="iconfont icon-xiangzuojiantou"></i>
        </div>
      </swiper>
    </div>
  </div>
</template>
<script lang="ts" setup>
useHead({
  title:'创建设计_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站',
  meta:[
    {hid:'keywords',name:'keywords','content':'创建设计_松鼠快图'},
    {hid:'description',name:'description','content':'松鼠快图创建设计页面。'},
  ]
})
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Navigation} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {getCreateDesignList, savelikestate} from "../../api/apiconfig/main";
import {getLocal, removeLocal} from "@/src/utils/local";
import { useLoginStore } from '@/src/store'
import {ElMessage} from "element-plus";
import {btoa} from 'js-base64';
const loginStore = useLoginStore()
const collectionShow = ref(false)
const showState = ref(-1);
const collectionShowState = ref(-1);
const menuIndex = ref(-1);
const fullscreenLoading = ref(true)
const modules = ref([Navigation]); // 配置swiper
const operationId = ref(-1);// 配置swiper控制器是否显示
const breakpointsData = ref(
    {'@0.00': {slidesPerView: "auto", spaceBetween: 30}}
)
const swiperHeight = ref('122px')
const swiperminWidth = ref('178px')
const widthValue = ref();
const heightValue = ref();
const customIf = ref(false);
//input正则表达式
const handleInput = ((value,index)=>{
  if(index===1){
    widthValue.value = value.replace(/\D/g,'')
  }else{
    heightValue.value = value.replace(/\D/g,'')
  }
})
const menuList = ref([])
const collectionImgList = ref([])
const imgList = ref([])
// 当鼠标划入swiper-slide 显示 swiper控制器
const handleMouseEnter = (id) => {
  operationId.value = id
}
// 当鼠标划出swiper-slide 隐藏 swiper控制器
const handleMouseLeave = () => {
  operationId.value = -1
}
const getMenu = ((index, item) => {
  menuIndex.value = index
  if (index === -1) {
    imgList.value = JSON.parse(JSON.stringify(menuList.value))
  } else {
    imgList.value = JSON.parse(JSON.stringify({item}))
  }
})
//自定义尺寸
const getCustom = (() => {
  customIf.value = !customIf.value
  heightValue.value = ''
  widthValue.value = ''
})
//收藏
const getCollection = ((id, index1, index2) => {
  //未登录调用登录弹窗
  if (getLocal("token")) {
    cancelFavorite(id, index1, index2)
  } else {
    loginStore.openloginstate()
  }
})
//收藏接口
const cancelFavorite = async (id, index1, index2) => {
  const response = await savelikestate({tid: id, uid: getLocal('uid'), type: 5})
  if (response === 1) {
    ElMessage.success('操作成功')
    if (index1 === 'collectionCancel') {
      getCollectionCancel(id, index1, index2)
    } else {
      collectionList(index1, index2)
    }
  } else {
    ElMessage.error('操作失败')
  }
}
//收藏/取消收藏数组处理方法
const collectionList = ((index1, index2) => {
  let data = imgList.value[index1].child[index2].likestate
  //取消收藏
  if (data) {
    imgList.value[index1].child[index2].likestate = false
    const index = collectionImgList.value.findIndex(item => item.id === imgList.value[index1].child[index2].id);
    if (index !== -1) {
      collectionImgList.value.splice(index, 1);
    }
    //收藏
  } else {
    imgList.value[index1].child[index2].likestate = true
    collectionImgList.value.push(imgList.value[index1].child[index2])
  }
})
//收藏取消收藏
const getCollectionCancel = ((id, index1, index2) => {
  toRaw(menuList.value).forEach((item, index) => {
    if (item.id === index2.pid) {
      toRaw(item.child).forEach((data, number) => {
        if (data.id === id) {
          imgList.value[index].child[number].likestate = false
        }
      })
    }
  });
  const index = collectionImgList.value.findIndex(item => item.id === id)
  if (index !== -1) {
    collectionImgList.value.splice(index, 1);
  }

})
//初始化
onMounted(() => {
  createDesignList()
})
//列表接口
const createDesignList = (async () => {
  const response = await getCreateDesignList()
  fullscreenLoading.value = false
  if (response.data.length !== 0) {
    //菜单
    menuList.value = response.data.parent
    //收藏菜单
    collectionImgList.value = response.data.mylike
    //默认图片列表
    getMenu(-1, menuList.value)
  }
})
//监听登录后调用接口数据
watch(() => loginStore.loginshowstate, (newValue, oldValue) => {
  if(newValue===false){
    createDesignList()
  }
})
</script>
<style>
html,
body,
#app,
#__nuxt {
  height: 100%;
}
</style>
<style lang='less' scoped>
.boxMargins {
  height: 100px;
  display: flex;
  flex-direction: column;
  padding: 10px;

  .box_title {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .custom_box {
      display: flex;
      align-items: center;
      justify-content: right;

      .custom {
        display: flex;
        align-items: center;

        .select {
          margin: 0 10px;
        }
      }

      .btn {
        height: 40px;
        margin-right: 10px;
        margin-left: 10px;
      }

    }
  }

  .box_menu {
    margin-top: 25px;
    display: flex;

    .box_menu_data:hover {
      color: black;
    }

    .box_menu_data {
      margin-right: 44px;
      color: #505a71;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      padding-bottom: 13px;
    }

    .box_menu_select {
      z-index: 1;
      padding-bottom: 10px;
      border-bottom: 3px solid var(--el-color-primary);
    }
  }

  .box_line {
    margin-top: -1px;
    border-bottom: 1px #F3F4F9 solid;
  }
}

.box_img {
  padding: 0 10px 30px;

  .swiper-slide {
    width: auto;
    position: relative;
    background-size: 100% 100%;
    border-radius: 4px;
    cursor: pointer;
  }

  .swiper-slide::after {
    background-color: rgba(0, 0, 0, .4);
  }

  .box_img_data {
    width: 211px;
    height: 172px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .image {
      height: 122px;
      width: 211px;
      border-radius: 8px;
      background-color: #F5F7FD;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      img {
        width: 120px;
        height: 90px;
        object-fit: contain;
        transition: transform 0.3s ease;
      }

      .collection {
        position: absolute;
        left: 8px;
        top: 8px;
        //opacity: 0;
        width: 32px;
        height: 32px;
        background: hsla(0, 0%, 100%, .7);
        border-radius: 8px;
        cursor: pointer;
        //z-index: 3;
        border: 1px solid hsla(0, 0%, 100%, .3);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          font-weight: 700;
        }
      }

      .establish {
        position: absolute;
        right: 8px;
        top: 8px;
        //opacity: 0;
        width: 32px;
        height: 32px;
        background: hsla(0, 0%, 100%, .7);
        border-radius: 8px;
        cursor: pointer;
        z-index: 3;
        border: 1px solid hsla(0, 0%, 100%, .3);
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          font-weight: 700;
        }
      }

      .writtenWords {
        position: absolute;
        bottom: 20px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        display: inline-block;
        text-shadow: 0 1px 0 rgba(0, 0, 0, .2);
        color: #FFFFFF;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }

      .enlarge {
        transform: scale(1.10); /* 放大 */
      }
    }

    .text {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #1b2337;
      text-align: center;
    }

    span {
      margin-top: 4px;
      font-size: 12px;
      line-height: 16px;
      color: #8693ab;
      text-align: center;
    }
  }
}


.swiper-button-next {
  width: 30px;
  height: 30px;
  margin-top: -35px;
  background: #fff;
  justify-content: center;
  align-items: center;
  box-shadow: 0 6px 12px 0 rgba(48, 62, 83, .14);
  border-radius: 50%;
  color: #333;

  i {
    font-size: 25px;
  }

}

.swiper-button-next::after {
  content: '';
}

.swiper-button-prev {
  width: 30px;
  height: 30px;
  margin-top: -35px;
  background: #fff;
  justify-content: center;
  align-items: center;
  box-shadow: 0 6px 12px 0 rgba(48, 62, 83, .14);
  border-radius: 50%;
  color: #333;

  i {
    font-size: 25px;
  }
}

.swiper-button-prev::after {
  content: '';
}

.overlayCreateDesign {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2); /* 半透明黑色遮罩 */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
</style>